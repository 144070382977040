@use "../../partials/partials" as *;

.footer-container {
  @include containerBase;
  margin: 5rem 0rem 0rem 0rem;
  padding: 1rem;
}

.footerTitle {
  font-size: 2rem;
  margin: 0.5rem;
  @include tablet {
    font-size: 2.5rem;
  }
  @include laptop {
    font-size: 3rem;
  }
  @include desktop {
    font-size: 3rem;
  }
  @include ultra {
    font-size: 5rem;
  }
}

.footerSubtitle {
  font-size: 1rem;
  margin: 0.5rem;
  @include tablet {
    font-size: 1.5rem;
  }
  @include laptop {
    font-size: 2rem;
  }
  @include desktop {
    font-size: 2rem;
  }
  @include ultra {
    font-size: 3rem;
  }
}

.links {
  color: $OrangeWeb;
}

.icon-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.5rem;
  height: 5rem;
  width: 18rem;
}

.email-outline {
  transform: scale(1.8);
  padding: 0.1rem;
  :hover {
    cursor: pointer;
    color: $GoldFusion;
  }
}

.email {
  transform: scale(1.8);
  padding: 0.1rem;
  :hover {
    cursor: pointer;
    color: $GoldFusion;
  }
}

.linked {
  transform: scale(1.8);
  padding: 0.1rem;
  text-decoration: none;
  :hover {
    cursor: pointer;
    color: $GoldFusion;
  }
}

.github {
  transform: scale(1.8);
  padding: 0.1rem;
  :hover {
    cursor: pointer;
    color: $GoldFusion;
  }
}

.twitter-icon {
  transform: scale(1.8);
  padding: 0.1rem;
  :hover {
    cursor: pointer;
    color: $GoldFusion;
  }
}

.instagram {
  transform: scale(1.8);
  padding: 0.1rem;
  :hover {
    cursor: pointer;
    color: $GoldFusion;
  }
}

.facebook {
  transform: scale(1.8);
  padding: 0.1rem;
  :hover {
    cursor: pointer;
    color: $GoldFusion;
  }
}
