@use "../../partials/partials" as *;

.project-flex {
  @include tablet {
  }
  @include laptop {
  }
  @include desktop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @include ultra {
  }
}

.card-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.jeopardy {
  background-image: url(../../assets/images/jeopardylogo.jpg);
  @include projectBase;
}

.twitter {
  background-image: url(../../assets/images/twitterunsplash.jpg);
  @include projectBase;
}

.airbnb {
  background-image: url(../../assets/images/filios-sazeides-uckPy5B7K4o-unsplash.jpg);
  @include projectBase;
}

.amazon {
  background-image: url(../../assets/images/christian-wiediger-rymh7EZPqRs-unsplash.jpg);
  @include projectBase;
}

.btn {
  border-radius: 4rem;
  height: 1.5rem;
  width: 6rem;
  background: linear-gradient(0turn, $MidnightBlue, $Royalblue, $MidnightBlue);
  border: 2px solid $GoldFusion;
  font-size: 1rem;
  font-weight: 700;
  margin: 0.5rem;
  color: $OrangeWeb;
  &:hover {
    color: $Blue;
    background: linear-gradient(0turn, $OrangeWeb, $Gold, $OrangeWeb);
  }
  @include tablet {
    font-size: 1.3rem;
    height: 2.5rem;
    width: 9rem;
  }
  @include laptop {
    font-size: 1.5rem;
    height: 2.5rem;
    width: 11rem;
  }
  @include desktop {
    font-size: 1.5rem;
    height: 2.5rem;
    width: 10rem;
  }
  @include ultra {
    font-size: 1.7rem;
    height: 3rem;
    width: 13rem;
  }
}

.btn-link {
  text-decoration: none;
}
