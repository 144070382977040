@use "../../partials/partials" as *;

.testimonials-container {
  @include containerBase;
  margin: 5rem 0rem;
  padding: 8rem 0rem;
}

.testimonialsTitle {
  font-size: 2rem;
  @include tablet {
    font-size: 1.5rem;
  }
  @include laptop {
    font-size: 2rem;
  }
  @include desktop {
    font-size: 2rem;
  }
  @include ultra {
    font-size: 3rem;
  }
}

.testimonialsSubtitle {
  font-size: 1rem;
  @include tablet {
    font-size: 1.5rem;
  }
  @include laptop {
    font-size: 2rem;
  }
  @include desktop {
    font-size: 2rem;
  }
  @include ultra {
    font-size: 3rem;
  }
}
