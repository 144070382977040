@use "../src/partials/partials" as *;

* {
  margin: 0rem;
  padding: 0rem;
}

.App {
  color: $OrangeWeb;
}
