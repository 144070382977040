@use "../../partials/partials" as *;

@mixin center() {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@import url(https://fonts.googleapis.com/css?family=Raleway:400,,800,900);
html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Raleway", sans-serif;
}

.home-container {
  @include containerBase;
  margin: 0rem 0rem 5rem 0rem;
  padding: 22rem 0rem;
}

.home-title {
  font-weight: 800;
  color: transparent;
  font-size: 4.25rem;
  background: url("https://media.giphy.com/media/26tn6Me6pD4Bel37G/giphy.gif")
    no-repeat;
  // https://media.giphy.com/media/26tn6Me6pD4Bel37G/giphy.gif
  // https://media.giphy.com/media/xThtajtWG4NvcL1LaM/giphy.gif
  // https://media.giphy.com/media/3o6ozFNmtVPIWbJQf6/giphy.gif
  // https://media.giphy.com/media/l396Gc7EV2YNcwXD2/giphy.gif
  // https://media.giphy.com/media/xT9IgusfDcqpPFzjdS/giphy.gif
  background-position: 40% 50%;
  background-size: 100%;
  background-clip: text;
  position: relative;
  text-align: center;
  line-height: 5.625rem;
  letter-spacing: -0.5rem;
  @include tablet {
    font-size: 9.25rem;
    line-height: 8.625rem;
  }

  @include laptop {
    font-size: 10.25rem;
    line-height: 9.625rem;
  }

  @include desktop {
    font-size: 10.75rem;
  }

  @include ultra {
    font-size: 11.25rem;
  }
}

.home-subtitle {
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding-top: 0.625rem;
  // font-size: 1.25rem;
  // line-height: 1.625rem;
  @include tablet {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }

  @include laptop {
    font-size: 1.5rem;
    line-height: 1.625rem;
  }

  @include desktop {
    font-size: 1.5rem;
    line-height: 1.625rem;
  }

  @include ultra {
    font-size: 1.75rem;
    line-height: 1.625rem;
  }
}
