@use "../../partials/partials" as *;

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}

.form-space {
  margin: 1rem 0rem;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  @include tablet {
    width: 40rem;
  }

  @include laptop {
    width: 1rem;
  }

  @include desktop {
    width: 1rem;
  }
}

.input {
  border-radius: 10px;
  border: 2px solid $GoldFusion;
  height: 2rem;
  width: 17rem;
  @include tablet {
    width: 30rem;
  }

  @include laptop {
    width: 35rem;
  }

  @include desktop {
    width: 35rem;
  }
}

.message {
  border-radius: 10px;
  border: 2px solid $GoldFusion;
  height: 10rem;
  width: 17rem;
  @include tablet {
    width: 30rem;
  }

  @include laptop {
    width: 35rem;
  }

  @include desktop {
    width: 35rem;
  }
}
.MuiOutlinedInput-notchedOutline {
  border-color: $GoldFusion !important;
}
.MuiDialogTitle-root {
  background: $Black;
  color: $OrangeWeb;
}

.MuiButton-root {
  margin: 0.5rem !important;
  border-radius: 30px !important;
}

.MuiButton-text {
  padding: 3px 6px !important;
  margin: 1px 13px !important;
}

.MuiButton-textPrimary {
  color: transparent !important;
  border: 2px solid $GoldFusion !important;
  padding: 3px 6px;
}

.MuiButton-textPrimary:hover {
  background-color: $GoldFusion !important;
  color: $White !important;
  border: 2px solid $GoldFusion !important;
}

.MuiDialogContentText-root {
  color: $OrangeWeb !important;
}

.MuiDialogContent-root {
  background: $Black;
  color: $OrangeWeb;
}

.MuiDialogActions-root {
  background: $Black;
  color: $OrangeWeb;
  padding: 0px !important;
  margin: 20px 1px 1px;
  width: 100%;
}

.MuiButton-label {
  color: $OrangeWeb;
}

.MuiButton-outlinedPrimary {
  color: $OrangeWeb !important;
  border: 2px solid $OrangeWeb !important;
}

.MuiButton-outlinedPrimary:hover {
  background-color: $GoldFusion !important;
  color: $White !important;
  border: 2px solid $GoldFusion !important;
}
label.MuiFormLabel-root {
  font-size: 1.3em;
}

div.MuiFilledInput-root {
  background-color: $LightBlack !important;
}

#nameStyle,
#emailStyle,
#subjectStyle,
#messageStyle {
  font: inherit;
  font-size: 1.25em;
  color: $OrangeWeb !important;
  width: 100%;
  border: 0;
  margin: 0;
  display: block;
  min-width: 0;
  background: $LightBlack;
  border-radius: 4px;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}
