@use "../../partials/partials" as *;

@mixin center() {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

@import url(https://fonts.googleapis.com/css?family=Raleway:400,,800,900);
html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Raleway", sans-serif;
}

.about-container {
  @include containerBase;
  margin: 5rem 0rem;
  padding: 8rem 0rem;
}

.about-title {
  font-weight: 800;
  color: transparent;
  font-size: 6.5rem;
  background: url("https://media.giphy.com/media/3ov9jR7qJvpnO5SW1W/giphy.gif")
    no-repeat;
  background-position: 40% 50%;
  background-size: 100%;
  background-clip: text;
  position: relative;
  text-align: center;
  line-height: 5.625rem;
  letter-spacing: -0.5rem;
  @include tablet {
    font-size: 9.25rem;
    line-height: 8.625rem;
  }

  @include laptop {
    font-size: 11.5rem;
    line-height: 9.625rem;
  }

  @include desktop {
    font-size: 11.5rem;
    line-height: 9.625rem;
  }

  @include ultra {
    font-size: 12.5rem;
    line-height: 10.625rem;
  }
}

.about-subtitle {
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding-top: 0.625rem;
  font-size: 1.25rem;
  @include tablet {
    font-size: 1.5rem;
  }
  @include laptop {
    font-size: 1.7rem;
  }
  @include desktop {
    font-size: 1.8rem;
  }

  @include ultra {
    font-size: 1.9rem;
  }
}

.portrait {
  border: 3px solid $GoldFusion;
  height: 20rem;
  width: 15rem;
  background-position: center left;
  border-radius: 240px;
  margin: 1rem;
  @include laptop {
    height: 25rem;
    width: 20rem;
  }
  @include desktop {
    height: 25rem;
    width: 20rem;
  }
  @include ultra {
    height: 30rem;
    width: 24rem;
  }
}

.portrait-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @include tablet {
    display: flex;
    flex-direction: row;
  }
}

.about-paragraph_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include tablet {
    width: 28rem;
    height: 42rem;
  }
  @include laptop {
    width: 41rem;
    height: 32rem;
  }
  @include desktop {
    width: 60rem;
    height: 28rem;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
  }
  @include ultra {
    width: 74rem;
    height: 33rem;
    display: flex;
    // flex-direction: column;
    // justify-content: center;
  }
}

.paragraph-top {
  font-size: 1.3rem;
  width: 18rem;
  padding: 0.5rem 1rem;
  margin: 1rem;
  @include tablet {
    width: 26rem;
    padding: 1rem;
    margin: 1rem;
  }
  @include laptop {
    font-size: 1.4rem;
    width: 39rem;
    padding: 1rem;
  }
  @include desktop {
    width: 50rem;
    padding: 1rem;
  }
  @include ultra {
    width: 70rem;
    padding: 1rem;
  }
}

.about-paragraph_bottom {
  @include tablet {
    width: 45rem;
    height: 26rem;
  }
  @include laptop {
    width: 64rem;
    height: 22rem;
  }
  @include desktop {
    width: 83rem;
    height: 21rem;
  }
  @include ultra {
    width: 100rem;
    height: 19rem;
  }
}

.paragraph-bottom {
  font-size: 1.3rem;
  width: 18rem;
  padding: 0.5rem 1rem;
  margin: 1rem;
  @include tablet {
    width: 43rem;
    padding: 1rem;
    margin: 1rem;
  }
  @include laptop {
    font-size: 1.4rem;
    width: 62rem;
    padding: 1rem;
  }
  @include desktop {
    width: 78rem;
    padding: 1rem;
  }
  @include ultra {
    width: 98rem;
    padding: 1rem;
  }
}
