@use "../../partials/partials" as *;

img {
  height: 150px;
  width: 250px;
}

.projects-container {
  @include containerBase;
  margin: 5rem 0rem;
  padding: 8rem 0rem;
}

.projects-title {
  font-size: 2rem;
  @include tablet {
    font-size: 2.5rem;
  }
  @include laptop {
    font-size: 3rem;
  }
  @include desktop {
    font-size: 3rem;
  }
  @include ultra {
    font-size: 5rem;
  }
}

.projects-subtitle {
  font-size: 1rem;
  @include tablet {
    font-size: 1.5rem;
  }
  @include laptop {
    font-size: 2rem;
  }
  @include desktop {
    font-size: 2rem;
  }
  @include ultra {
    font-size: 3rem;
  }
}
