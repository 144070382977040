@use "../../partials/partials" as *;

.testimonialCard-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  width: 19rem;
  margin: 1rem;
  @include tablet {
    width: 40rem;
    margin: 2rem;
  }
  @include laptop {
    width: 15rem;
    margin: 2rem;
  }
  @include desktop {
    width: 25rem;
    margin: 2rem;
  }
  @include ultra {
    width: 35rem;
    margin: 3rem;
    font-size: 1.5rem;
  }
}

.testimonial-flex {
  @include laptop {
    display: flex;
    flex-wrap: wrap;
  }
  @include desktop {
    display: flex;
  }
  @include ultra {
    display: flex;
  }
}

.test-pic-one {
  background-image: url(../../assets/images/pepe-alt-unsplash.jpg);
  @include testimonialBase;
}
