@use "../../partials/partials" as *;

$icon-size: 80px;
$w: 300px;
@import url(https://fonts.googleapis.com/css?family=Open+Sans);

@mixin lightsaber($color) {
  background: mix($color, #fff, 10%);
  box-shadow: $color 0px 0px 0px 2px;
  &:hover {
    box-shadow: $color 0px 0px 20px 6px;
    text-shadow: $color 0px 0px 6px, $color 0px 0px 10px;
  }
}

@mixin laptopMenu($color) {
  &:hover {
    background: mix($color, #fff, 10%);
    box-shadow: $color 0px 0px 20px 6px;
    text-shadow: $color 0px 0px 6px, $color 0px 0px 10px;
  }
}

* {
  box-sizing: border-box;
}

#container {
  width: $icon-size;
  height: $icon-size;
  position: fixed;
  top: 0%;
  right: 0%;
  z-index: 100;
}

#burger {
  cursor: pointer;
  opacity: 0;
  animation: fadein 0.2s ease 1s forwards;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100;
  %burgersection {
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    height: 11%;
    width: 85%;
    transform-origin: 50% 50%;
    transition: top 0.4s ease 0.4s, transform 0.4s ease 0.4s;
    &:before,
    &:after {
      content: "";
      display: block;
      height: 40%;
      background: white;
      position: absolute;
      top: 50%;
      transform: translate(0%, -45%);
      transition: background 0.2s ease, box-shadow 0.2s ease;
    }
    &:before {
      left: 0;
      width: calc(-1px + 75%);
      border-radius: 10px 0px 0px 10px;
    }
    &:after {
      right: 0;
      width: calc(-1px + 25%);
    }
  }

  &:hover {
    .bun {
      &.top:before {
        background: #fee;
        box-shadow: #f00 0px 0px 10px 10px;
      }
      &.bottom:before {
        background: #dff;
        box-shadow: #0ff 0px 0px 10px 6px;
      }
    }
    .filling {
      &:before {
        background: #efe;
        box-shadow: #0f0 0px 0px 10px 6px;
      }
    }
  }

  .bun {
    @extend %burgersection;
    &.top {
      top: 25%;
    }
    &.bottom {
      top: 75%;
    }
  }
  .filling {
    @extend %burgersection;
    top: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
    animation: green-ls-out 0.8s ease forwards;
  }

  &.active {
    .bun {
      border-radius: 3px;
      top: 50%;
      transition: top 0.4s ease, transform 0.4s ease;
      &.top {
        transform: translate(-50%, -50%) rotate(-225deg);
      }
      &.bottom {
        transform: translate(-50%, -50%) rotate(405deg);
      }
    }
    .filling {
      transform: translate(-50%, -50%) rotate(-90deg);
      animation: green-ls-in 0.8s ease forwards;
    }
  }
}

nav {
  &.show {
    transform: translatex(0%);
  }
  font-family: Open Sans;
  color: white;
  background: #111;
  text-transform: uppercase;
  background: $Black;
  position: fixed;
  transform: translatex(100%);
  transition: transform 0.2s ease;
  top: $icon-size;
  right: 0px;
  padding-left: 16px;
  width: $w;
  z-index: 100;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    li {
      @include lightsaber(#4df);
      font-size: 1.75em;
      line-height: 1.25em;
      padding: 0px;
      padding-left: 0px;
      margin: 8px 0px;
      width: 0;
      border-radius: 100px 0px 0px 100px;
      transition: width 0.2s ease, padding 0.2s ease, color 0.2s ease,
        text-shadow 0.2s ease;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &:hover {
        width: 100%;
        padding: 0px 0px 0px 16px;
        color: $Black;
      }
      a {
        display: flex;
        justify-content: flex-end;
        text-decoration: none;
        width: $w;
        color: inherit;
        padding-right: 16px;
      }
      &.green {
        @include lightsaber(#0f0);
      }
      &.red {
        @include lightsaber(#f00);
      }
      &.purple {
        @include lightsaber(#c0c);
      }
      &.yellow {
        @include lightsaber(#ff0);
      }
    }
  }
}

@keyframes green-ls-in {
  0% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-90deg) translate(200%, 0%);
  }
}

@keyframes green-ls-out {
  0% {
    transform: translate(-50%, -200%) rotate(-90deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@include mobile {
  .laptop-nav {
    display: none;
  }
}

@include laptop {
  .mobile-nav {
    display: none;
  }
  .laptop-nav {
    display: block !important;
    position: fixed;
    right: 0px;
    padding: 20px 0px;
    width: 100%;
    background: linear-gradient(to right, $LightBlack 0%, $Black 85%);
    z-index: 100;
    ul {
      display: flex;
      list-style: none;
      justify-content: flex-end;
      li {
        @include laptopMenu(#4df);
        padding: 0px 20px;
        font-size: 1.75em;
        color: $White;
        &:hover {
          border-radius: 50px;
          color: black;
        }
        a {
          text-decoration: none;
          color: inherit;
        }
        &.green {
          @include laptopMenu(#0f0);
        }
        &.red {
          @include laptopMenu(#f00);
        }
        &.purple {
          @include laptopMenu(#c0c);
        }
        &.yellow {
          @include laptopMenu(#ff0);
        }
      }
    }
  }
}
