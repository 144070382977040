//Display Breakpoints Variables
$mobile-break: 240px;
$tablet-break: 768px;
$laptop-break: 1024px;
$desktop-break: 1440px;
$ultra-break: 2560px;

//Colors Variables
$Black: #000000;
$LightBlack: #262626;
$Onyx: #393e41;
$Darkgray: #474653;
$Gray: #857f83;
$Silver: #9ab4d0;
$Blue: #060ce9;
$Royalblue: #0000f2;
$OxfordBlue: #14213d;
$MidnightBlue: #090446;
$Darkblue: #0e0039;
$GoldFusion: #786f52;
$Green: #008000;
$Emerald: #0e1d15;
$Yellow: #ffcc00;
$Gold: #ffd700;
$Orange: #fa9f3c;
$OrangeWeb: #fca311;
$PersianPlum: #6b0f1a;
$DeepPurple: #20152e;
$Purple: #610578;
$Fushia: #e500eb;
$Pink: #d63369;
$Red: #9b2b34;
$Darkred: #622638;
$Tan: #e1b6bc;
$Timberwolf: #d3d0cb;
$Platinum: #e7e5df;
$White: #ffffff;

//Fonts Variables
//Font Weights Variables
$fontreg: 300;
$fontdemibold: 500;
$fontnextbold: 700;

//Desktop & Tablet Font Sizes Variables

//Mobile Font Sizes Variables

//Font Styles Variables

//Mixin Variables
@mixin mobile {
  @media screen and (min-width: $mobile-break) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet-break) {
    @content;
  }
}

@mixin laptop {
  @media screen and (min-width: $laptop-break) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-break) {
    @content;
  }
}

@mixin ultra {
  @media screen and (min-width: $ultra-break) {
    @content;
  }
}

@mixin projectBase {
  background-position: center center;
  background-size: cover;
  border: 3px solid $GoldFusion;
  border-radius: 2rem;
  height: 12rem;
  width: 17rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
  @include tablet {
    height: 20rem;
    width: 35rem;
  }
  @include laptop {
    height: 25rem;
    width: 40rem;
  }
  @include desktop {
    height: 20rem;
    width: 40rem;
    margin: 0rem;
  }
  @include ultra {
    height: 30rem;
    width: 40rem;
    margin: 1rem;
  }
}

@mixin testimonialBase {
  background-position: center center;
  background-size: cover;
  border: 3px solid $GoldFusion;
  border-radius: 30rem;
  height: 10rem;
  width: 10rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin: 1rem;
  @include tablet {
    border-radius: 10rem;
    height: 15rem;
    width: 15rem;
  }
  @include laptop {
    border-radius: 10rem;
    height: 15rem;
    width: 15rem;
  }
  @include desktop {
    border-radius: 10rem;
    height: 20rem;
    width: 15rem;
  }
  @include ultra {
    border-radius: 10rem;
    height: 20rem;
    width: 15rem;
  }
}

@mixin containerBase {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: auto;
}
